<script setup>
import { ref } from 'vue'
import { Link } from '@inertiajs/vue3'
import { Container, DataTable, PrimaryButton } from '@codinglabsau/ui'
import moment from 'moment'

import { ship_image } from '@/helpers'
import HeadMetaTags from '@/Components/HeadMetaTags.vue'
import PageHeader from '@/Components/PageHeader.vue'
import ReputationModal from '@/Components/Reputation/ReputationModal.vue'

const props = defineProps({
  ships: Object,
  can: Object,
})

const search = ref(route().params.filter && route().params.filter.q ? route().params.filter.q : '')

const dataTableConfig = {
  columns: [
    {
      text: 'Ship',
      value: 'name',
      sortable: true,
      compact: true,
    },
    {
      text: 'Voyages',
      value: 'voyages',
    },
    {
      text: 'Total Passengers',
      value: 'voyages_sum_passenger_count',
    },
  ],
  collection: props.ships,
  search: search.value,
  enableSearch: true,
}
</script>

<template>
  <HeadMetaTags
    title="Ships"
    description="A Index of all of our recorded ships that transported Convicts to Australia."
    :canonical="route('ships.index')"
    :url="route('ships.index')"
  />

  <Container>
    <PageHeader title="Ships">
      <template #actions>
        <PrimaryButton v-if="can.create" rel="nofollow" :href="route('ships.create')">
          Add a Ship
        </PrimaryButton>
        <div v-else>
          <ReputationModal>
            <template #trigger>
              <PrimaryButton> Add a Ship </PrimaryButton>
            </template>
          </ReputationModal>
        </div>
      </template>
    </PageHeader>
    <div class="rounded-b-lg bg-white p-3 pt-1">
      <DataTable v-bind="dataTableConfig" v-model:search="search" class="mt-8">
        <template #item.name="{ item }">
          <Link
            :href="route('ships.show', item.url_name)"
            class="flex w-fit items-center text-primary visited:text-visited hover:underline"
          >
            <img :src="ship_image(item.image)" :alt="item.name" class="mr-4 size-24 rounded-sm" />

            <div class="max-w-lg overflow-hidden pr-5">
              <div class="truncate font-bold">{{ item.name }}</div>
              <div class="line-clamp-2 whitespace-break-spaces">{{ item.description }}</div>
            </div>
          </Link>
        </template>

        <template #item.voyages="{ item }">
          <div v-if="item.voyages.length > 0">
            <div v-for="voyage in item.voyages" :key="voyage.id">
              <Link :href="route('ships.voyages.show', [item.url_name, voyage.id])">
                {{ moment(voyage.departure_date).format('Do MMM YYYY') }}
              </Link>
            </div>
          </div>
          <div v-else>Voyages not recorded</div>
        </template>

        <template #item.description="{ item }">
          <div class="max-w-xs">
            <span class="truncate">{{ item.description }}</span>
          </div>
        </template>

        <template #item.voyages_sum_passenger_count="{ item }">
          <div v-if="item.voyages.length > 0">
            {{ parseInt(item.voyages_sum_passenger_count).toLocaleString() }}
          </div>
          <div v-else>0</div>
        </template>
      </DataTable>
    </div>
  </Container>
</template>
