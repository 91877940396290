<script setup>
import { Head, useForm } from '@inertiajs/vue3'
import { Button, Input, Label } from '@codinglabsau/gooey'
import AuthLayout from '@/Layouts/Auth.vue'
import BreezeValidationErrors from '@/Components/ValidationErrors.vue'

const form = useForm({
  password: '',
})

const submit = () => {
  form.post(route('password.confirm'), {
    onFinish: () => form.reset(),
  })
}

defineOptions({ layout: AuthLayout })
</script>

<template>
  <Head title="Confirm Password" />

  <div class="mb-4 text-sm text-gray-600">
    This is a secure area of the application. Please confirm your password before continuing.
  </div>

  <BreezeValidationErrors class="mb-4" />

  <form @submit.prevent="submit">
    <div>
      <Label for="password" value="Password" />
      <Input
        id="password"
        v-model="form.password"
        type="password"
        class="mt-1 block w-full"
        required
        autocomplete="current-password"
        autofocus
      />
    </div>

    <div class="mt-4 flex justify-end">
      <Button class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
        Confirm
      </Button>
    </div>
  </form>
</template>
