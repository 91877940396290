<script setup>
import { Head, Link, useForm } from '@inertiajs/vue3'
import { Button, Input, Label } from '@codinglabsau/gooey'
import { CheckCircleIcon } from '@heroicons/vue/24/solid'
import { HeartIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import Google from '@/Components/Icons/Google.vue'
import AuthLayout from '@/Layouts/Auth.vue'
import FacebookIcon from '@/Components/FacebookIcon.vue'
import BreezeValidationErrors from '@/Components/ValidationErrors.vue'
import { ref } from 'vue'

const props = defineProps({
  facebookEnabled: Boolean,
  googleEnabled: Boolean,
  externalProviderDetails: {
    type: Object,
    required: false,
  },
})

const form = useForm({
  name: props.externalProviderDetails?.name,
  email: props.externalProviderDetails?.email,
  password: '',
  password_confirmation: '',
  terms: false,
  oauth_provider: props.externalProviderDetails?.oauth_provider,
  oauth_uid: props.externalProviderDetails?.oauth_uid,
})

const submit = () => {
  form
    .transform((data) => ({
      ...data,
      plan: plan.value,
    }))
    .post(route('register'), {
      onFinish: () => form.reset('password', 'password_confirmation'),
    })
}

defineOptions({ layout: AuthLayout })

const plan = ref(null)
</script>

<template>
  <Head title="Register" />

  <h2 class="my-3 text-2xl font-bold text-gray-800">Register an account</h2>

  <p class="mb-3 mt-2 text-gray-500">
    Create an account to trace your ancestry, contribute family stories, and connect with others
    exploring their past.
  </p>

  <BreezeValidationErrors class="mb-4" />

  <form class="space-y-4" @submit.prevent="submit">
    <div class="space-y-2">
      <div>
        <Label for="name"> Name </Label>
        <Input
          id="name"
          v-model="form.name"
          autocomplete="name"
          autofocus
          required
          type="text"
          class="focus:border-primary-600 focus-visible:ring-primary-600"
        />
      </div>

      <div>
        <Label for="email"> Email </Label>
        <Input
          id="email"
          v-model="form.email"
          :disabled="externalProviderDetails?.email"
          autocomplete="username"
          required
          type="email"
          class="focus:border-primary-600 focus-visible:ring-primary-600"
        />
      </div>

      <div>
        <Label for="password"> Password </Label>
        <Input
          id="password"
          v-model="form.password"
          autocomplete="new-password"
          required
          type="password"
          class="focus:border-primary-600 focus-visible:ring-primary-600"
        />
      </div>

      <div>
        <Label for="password_confirmation"> Confirm Password </Label>
        <Input
          id="password_confirmation"
          v-model="form.password_confirmation"
          autocomplete="new-password"
          required
          type="password"
          class="focus:border-primary-600 focus-visible:ring-primary-600"
        />
      </div>

      <div>
        <hr class="mt-8" />
      </div>

      <div>
        <div class="mb-3 mt-5 flex items-center">
          <HeartIcon class="me-1 inline-block size-5 text-primary-600" />
          <h3 class="text-lg font-medium text-gray-800">Support Us and Unlock new features!</h3>
        </div>
        <p class="my-3 text-sm text-gray-500">
          Your support helps us to continue to grow Convict Records as we work alongside our
          community to bring new features and improvements to the platform.
        </p>
        <ul class="w-full gap-6 space-y-2 xs:grid xs:space-y-0 md:grid-cols-2">
          <li>
            <input
              id="plan-monthly"
              v-model="plan"
              type="radio"
              name="plan"
              value="monthly"
              class="peer hidden"
            />
            <label
              for="plan-monthly"
              class="inline-flex w-full cursor-pointer items-center justify-between rounded-lg border border-gray-200 bg-white p-5 text-gray-500 outline-primary-600 hover:bg-gray-100 hover:text-gray-600 peer-checked:border-primary-600 peer-checked:text-primary-600"
              role="radio"
              :aria-checked="plan === 'monthly'"
              tabindex="0"
              @keyup.space.enter="() => (plan = 'monthly')"
            >
              <div class="block">
                <div class="w-full text-lg font-semibold">Monthly</div>
                <div class="w-full">$10 per month</div>
              </div>
              <svg
                v-if="plan !== 'monthly'"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="ms-3 size-5"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <CheckCircleIcon v-if="plan === 'monthly'" class="ms-3 size-5" />
            </label>
          </li>
          <li>
            <input
              id="plan-annually"
              v-model="plan"
              type="radio"
              name="plan"
              value="annually"
              class="peer hidden"
            />
            <label
              for="plan-annually"
              class="inline-flex w-full cursor-pointer items-center justify-between rounded-lg border border-gray-200 bg-white p-5 text-gray-500 outline-primary-600 hover:bg-gray-100 hover:text-gray-600 peer-checked:border-primary-600 peer-checked:text-primary-600"
              role="radio"
              :aria-checked="plan === 'annually'"
              tabindex="0"
              @keyup.space.enter="() => (plan = 'annually')"
            >
              <div class="block">
                <div class="w-full text-lg font-semibold">Annually</div>
                <div class="w-full">$100 per year</div>
              </div>
              <svg
                v-if="plan !== 'annually'"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="ms-3 size-5"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <CheckCircleIcon v-if="plan === 'annually'" class="ms-3 size-5" />
            </label>
          </li>

          <li class="col-span-2">
            <input
              id="plan-free"
              v-model="plan"
              type="radio"
              name="plan"
              value="free"
              class="peer hidden"
            />
            <label
              for="plan-free"
              class="inline-flex w-full cursor-pointer items-center justify-between rounded-lg border border-gray-200 bg-white p-3 text-gray-500 outline-primary-600 hover:bg-gray-100 hover:text-gray-600 peer-checked:border-primary-600 peer-checked:text-primary-600"
              role="radio"
              :aria-checked="plan === 'free'"
              tabindex="0"
              @keyup.space.enter="() => (plan = 'free')"
            >
              <div class="block">
                <div class="w-full text-sm font-semibold">No thanks</div>
                <div class="w-full text-xs">Continue for Free</div>
              </div>
              <XMarkIcon class="ms-3 size-5" />
            </label>
          </li>
        </ul>
      </div>
    </div>

    <Button :class="{ 'opacity-25': form.processing }" :disabled="form.processing" class="w-full">
      Register
    </Button>

    <div v-if="facebookEnabled || googleEnabled" class="flex w-full gap-4">
      <div class="my-4 h-px w-1/2 rounded bg-gray-200"></div>
      <span class="my-auto text-gray-500"> or </span>
      <div class="my-4 h-px w-1/2 rounded bg-gray-200"></div>
    </div>

    <div class="mx-auto flex flex-col space-y-2">
      <Button v-if="facebookEnabled" variant="outline" class="w-full" as-child>
        <a :href="route('oauth.facebook')" class="flex gap-1">
          <FacebookIcon class="my-auto size-4 text-blue-500" />
          Register with Facebook
        </a>
      </Button>

      <Button v-if="googleEnabled" variant="outline" class="w-full" as-child>
        <a :href="route('oauth.google')" class="flex gap-1">
          <Google class="my-auto size-4" />
          Register with Google
        </a>
      </Button>
    </div>
  </form>

  <div class="-mx-6 -my-4 mt-8 bg-stone-200">
    <div class="px-8 py-2 text-center text-xs text-gray-700">
      Already registered?
      <Link :href="route('login')" class="font-medium underline"> Login instead </Link>
    </div>
  </div>
</template>
