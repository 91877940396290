<script setup>
import { computed } from 'vue'
import { Head } from '@inertiajs/vue3'
import LinkShareLarge from '@images/link-share-large.png'

const props = defineProps({
  title: {
    type: [String, null],
    required: false,
  },
  description: {
    type: String,
    required: false,
    default: 'Community Driven Database for Convicts transported to Australia from 1787 to 1867.',
  },
  url: {
    type: String,
    required: false,
    default: route('index'),
  },
  canonical: {
    type: String,
    required: false,
    default: route('index'),
  },
  noIndex: {
    type: Boolean,
    required: false,
    default: undefined,
  },
})

const noIndexOverride = computed(() => {
  if (props.noIndex) {
    return props.noIndex
  }

  return (
    Boolean(route().params.q) || Boolean(route().params.filter) || Boolean(route().params.filter?.q)
  )
})
</script>

<template>
  <Head :title="title">
    <link rel="canonical" :href="canonical" />

    <meta v-if="noIndexOverride" name="robots" content="noindex" />
    <meta name="description" :content="description" />

    <meta property="og:type" head-key="og:type" content="website" />
    <meta property="og:image" head-key="og:image" :content="LinkShareLarge" />
    <meta property="og:image:width" head-key="og:image:width" content="600" />
    <meta property="og:image:height" head-key="og:image:height" content="300" />
    <meta property="og:image:alt" head-key="og:image:alt" content="Convict Records" />
    <meta property="og:url" head-key="og:url" :content="url" />
    <meta property="og:description" head-key="og:description" :content="description" />

    <meta name="twitter:card" head-key="twitter:card" content="summary" />
    <meta name="twitter:description" head-key="twitter:description" :content="description" />
  </Head>
</template>
