<script setup>
import { Head, useForm } from '@inertiajs/vue3'
import { Button, Input, Label } from '@codinglabsau/gooey'
import AuthLayout from '@/Layouts/Auth.vue'
import BreezeValidationErrors from '@/Components/ValidationErrors.vue'

defineProps({
  status: String,
})

const form = useForm({
  email: '',
})

const submit = () => {
  form.post(route('password.email'))
}

defineOptions({ layout: AuthLayout })
</script>

<template>
  <Head title="Forgot Password" />

  <div class="mb-4 text-sm text-gray-600">
    Forgot your password? No problem. Just let us know your email address and we will email you a
    password reset link that will allow you to choose a new one.
  </div>

  <div v-if="status" class="mb-4 text-sm font-medium text-green-600">
    {{ status }}
  </div>

  <BreezeValidationErrors class="mb-4" />

  <form @submit.prevent="submit">
    <div>
      <Label for="email" value="Email" />
      <Input
        id="email"
        v-model="form.email"
        type="email"
        class="mt-1 block w-full"
        required
        placeholder="Email"
        autofocus
        autocomplete="username"
      />
    </div>

    <div class="mt-4 flex items-center justify-end">
      <Button :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
        Email Password Reset Link
      </Button>
    </div>
  </form>
</template>
