<script setup>
import { ArrowRightIcon } from 'lucide-vue-next'
import { FormBuilder, useSchema } from '@codinglabsau/inertia-form-builder'
import {
  Button,
  Dialog,
  DialogDescription,
  DialogHeader,
  DialogScrollContent,
  DialogTitle,
  DialogTrigger,
  Textarea,
} from '@codinglabsau/gooey'

const props = defineProps({
  convict: {
    type: Object,
    required: true,
  },
  open: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:open'])

const schema = useSchema({
  description: {
    component: Textarea,
  },
})

const closeModal = () => {
  emit('update:open', false)
}

const submit = () =>
  schema.form.post(route('convicts.claim', props.convict), {
    preserveScroll: true,
    onSuccess: () => {
      closeModal()
    },
  })
</script>

<template>
  <Dialog>
    <DialogTrigger as-child>
      <Button variant="outline">Claim Now <ArrowRightIcon class="ml-2 size-4" /></Button>
    </DialogTrigger>

    <DialogScrollContent class="max-w-lg">
      <DialogHeader class="text-left">
        <DialogTitle>{{ convict.name }}</DialogTitle>
        <DialogDescription>
          By claiming a convict, you'll have your name featured on their page. Feel free to make
          your claim for any reason – whether it's a familial connection or any other meaningful
          association. We just ask that you provide a brief description of your connection with the
          convict.
        </DialogDescription>
      </DialogHeader>

      <form @submit.prevent="submit">
        <FormBuilder :schema="schema" />
      </form>
    </DialogScrollContent>
  </Dialog>
</template>
