<script setup>
import { computed } from 'vue'

const props = defineProps({
  title: String,
  as: {
    type: String,
    default: 'h2',
  },
})

const titleClass = computed(() => {
  if (props.as === 'h2') {
    return 'text-xl font-semibold md:text-2xl'
  } else if (props.as === 'h1') {
    return 'text-2xl font-bold md:text-3xl'
  }

  return ''
})
</script>

<template>
  <component
    :is="as"
    :id="title"
    class="font-serif text-stone-600"
    :class="[titleClass]"
    aria-label="Heading"
  >
    {{ title }}
  </component>
</template>