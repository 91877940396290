<script setup>
</script>

<template>
  <div
    class="flex flex-wrap items-center justify-between space-y-2 border-b border-zinc-200 pb-2"
  >
    <slot name="title" />

    <slot name="actions" />
  </div>
</template>